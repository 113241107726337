export const enum Routes {
    Both = "both",
    BothGoogle = "both_google",
    Flow = "main-flow",
    Loader = "loader",
    PromoPaywall = "paywall",
    Friends = "friends",
    NewYear = "newyear",
    ChallengesRedirect = "challenges-redirect",
    Age = "age",
    Calisthenics = "calisthenics",
    Offer = "offer",
    Funnel = "funnel",
    WeightLoss = "weight-loss",
}
