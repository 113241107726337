import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { IS_PROD_BUILD } from "@/constants/env";
import { Languages } from "@/constants/languages";

import { getLanguage } from "./utils";

const loadPath = IS_PROD_BUILD
    ? `/${process.env.npm_package_version}/locales/{{lng}}/{{ns}}.json`
    : "/src/localization/locales/{{lng}}/{{ns}}.json";

import translationEN from "@/localization/locales/en/translation.json";
import translationES from "@/localization/locales/es/translation.json";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        resources: {
            en: {
                translation: translationEN,
            },
            es: {
                translation: translationES,
            },
        },
        ns: "translation",
        fallbackLng: Languages.English,
        lng: getLanguage(),
        debug: true,
        load: "languageOnly",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath,
        },
    });

export default i18n;
