export enum Languages {
    Spanish = "es",
    English = "en",
    German = "de",
}

export enum LocalizedLanguages {
    EnglishUS = "en-US",
    SpanishES = "es-ES",
    GermanDE = "de-DE",
}

export const languagesStrings: Record<Languages, string> = {
    [Languages.Spanish]: "spanish",
    [Languages.English]: "english",
    [Languages.German]: "german",
};

export const getLanguage = (language: string): Languages => {
    // See GW-298 Roll back German localization
    const keys = Object.keys(Languages).filter(
        (lng) => lng !== "German"
    ) as (keyof typeof Languages)[];
    for (const key of keys) {
        if (language.includes(Languages[key])) {
            return Languages[key];
        }
    }

    return Languages.English;
};

export const mapLocalizedLanguage = {
    [Languages.Spanish]: LocalizedLanguages.SpanishES,
    [Languages.English]: LocalizedLanguages.EnglishUS,
    [Languages.German]: LocalizedLanguages.GermanDE,
};
